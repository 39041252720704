import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import OGImage from '../images/favicon-black.png';

function ThankYou({ location }) {
  const data = useStaticQuery(
    graphql`
      query {
        login_bg: file(relativePath: { eq: "account-bg.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );
  const params = new URLSearchParams(location.search);
  const bg_login = getImage(data.login_bg);
  // Use like this: bg-accounts-bg bg-cover bg-center
  const loginBg = convertToBgImage(bg_login);

  const url = typeof window !== 'undefined' ? window.location.origin : '';

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${url}/thank-you`} />
        <meta http-equiv="Content-Type" content="text/xml; charset=UTF-8" />
        <meta property="og:title" content="Thank You | Girar World" />
        <meta property="description" content="Girar, go on a journey" />
        <meta property="og:description" content="Girar, go on a journey" />
        <meta property="og:url" content={`${url}/thank-you`} />
        <title>Thank You | Girar World</title>
        <meta property="og:image" content={`${url}${OGImage}`} />
        <meta name="twitter:url" content={`${url}/thank-you`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@WeltgeistKiran" />
        <meta name="twitter:title" content="Thank You | Girar World" />
        <meta name="twitter:description" content="Girar, go on a journey" />
        <meta property="twitter:image" content={`${url}${OGImage}`} />
        <meta property="og:type" content="website" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Rokkitt:wght@400;500;600;700;800;900&family=Telex&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <BackgroundImage
        Tag="section"
        className="bg-center"
        // Spread bgImage into BackgroundImage:
        {...loginBg}>
        <section className="relative w-full h-screen">
          <div className="max-w-screen-2xl mx-auto grid grid-cols-12">
            <div className="col-span-12 mt-20 px-20 text-center">
              <h1 className="font-opensans xl:text-4xl text-2xl text-white">Donation Successful</h1>
              <p className="font-rokkit xl:text-2xl text-base text-white mt-3">Thank you for your supporting Girar World</p>
            </div>
          </div>
        </section>
      </BackgroundImage>
    </>
  );
}

export default ThankYou;
